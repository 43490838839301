import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import ContentColumn from '../components/content-column/content-column';
import PageWrapper from '../components/page-wrapper/page-wrapper';

const ImprintPage = () => {
  const { t } = useTranslation('imprint');

  return (
    <PageWrapper hero={false}>
      <SEO title={t('title')} />
      <HeroState clear />
      <Container>
        <Row>
          <ContentColumn md={{ offset: 1, span: 10 }}>
            <Trans i18nKey="content" ns="imprint">
              <h1>Impressum</h1>
              <h2>Angaben gemäß § 5 TMG</h2>
              <p>
                Caffè A Mano GmbH
                <br />
                Kleinhohenheimerstr. 5
                <br /> 70619 Stuttgart
                <br />
                Deutschland
              </p>
              <p>
                Ust.-ID: DE309206602
                <br />
                Handelsregister: HRB 768678 Amtsgericht Stuttgart
              </p>
              <p>Geschäftsführer: Henrik Mattern</p>
              <h2>Telefonnummer</h2>
              <p>
                <a href="tel:+49 711 25259630">+49 711 25259630</a>
              </p>
              <h2>E-Mail</h2>
              <p>
                <a href="mailto:info@manument.com">info@manument.com</a>
              </p>
              <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
              <p>
                Caffè A Mano GmbH
                <br />
                Kleinhohenheimerstr. 5
                <br />
                70619 Stuttgart
                <br />
                Deutschland
              </p>

              <h2>§5 Haftungshinweis</h2>
              <p>
                Für die Inhalte der verlinkten Seiten ist jeweils der
                Seitenbetreiber oder Anbieter der Seite verantwortlich.
              </p>
            </Trans>
          </ContentColumn>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default ImprintPage;
